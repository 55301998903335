<template>
    <el-dialog
            :visible.sync="dialogVisible"
            width="698px"
            top="6vh"
            :before-close="closeDoubleDrainageDialog">
        <template slot="title">
            <title-icon/>双泵排水泵
        </template>
        <div style="width: 100%;height: 430px;">
            <div class="doubleDrainageStyle">
                <div class="fireElevator-high">
                    <p>高水位报警</p>
                    <template v-if="monitorType010003 == 'true'">
                        <span class="fh-blow-btn fault">报警</span>
                    </template>
                    <template v-else>
                        <span class="fh-blow-btn">正常</span>
                    </template>
                </div>
                <div class="fireElevator-auto">
                    <p>手/自动状态</p>
                    <template v-if="monitorType013001 == 'true'">
                        <span class="fh-blow-btn autoHand">自动</span>
                    </template>
                    <template v-else>
                        <span class="fh-blow-btn autoHand">手动</span>
                    </template>
                </div>
            </div>
            <div class="fireElevator-b" style="text-align: center;">
                <div class="fireElevator-b-l" style="float: left;margin-left: 130px;">
                    <p class="blowdown-title">1#泵</p>
                    <p>
                        <span>运行状态</span>
                        <template v-if="monitorType001001 == 'true'">
                            <span class="fh-blow-btn">运行</span>
                        </template>
                        <template v-else>
                            <span class="fh-blow-btn stop">停止</span>
                        </template>
                    </p>
                    <p>
                        <span>故障报警</span>
                        <template v-if="monitorType010004 == 'true'">
                            <span class="fh-blow-btn fault">故障</span>
                        </template>
                        <template v-else>
                            <span class="fh-blow-btn">正常</span>
                        </template>
                    </p>
                </div>
                <div class="fireElevator-b-r" style="float: left;margin-left: 60px;">
                    <p class="blowdown-title">2#泵</p>
                    <p>
                        <span>运行状态</span>
                        <template v-if="monitorType001002 == 'true'">
                            <span class="fh-blow-btn">运行</span>
                        </template>
                        <template v-else>
                            <span class="fh-blow-btn stop">停止</span>
                        </template>
                    </p>
                    <p>
                        <span>故障报警</span>
                        <template v-if="monitorType010005 == 'true'">
                            <span class="fh-blow-btn fault">故障</span>
                        </template>
                        <template v-else>
                            <span class="fh-blow-btn">正常</span>
                        </template>
                    </p>
                </div>
            </div>
        </div>
        <div slot="footer">
            <el-button type="primary" @click="closeDoubleDrainageDialog">{{$t('commons.close')}}</el-button>
        </div>
    </el-dialog>
</template>

<script>
    export default {
        name: 'DoubleDrainage',
        props: {
            // //是否显示
            // dialogVisible: {
            //     type: Boolean
            // },
            // 报警信息
            deviceInfo: {
                type: Object,
            },
            // 设备类型编码
            deviceType: {
                type: String,
            },
        },
        computed: {
            telecommandMessage() {
                return this.$store.getters.telecommandMessage;
            },
            telemeteringMessage(){
                return this.$store.getters.telemeteringMessage;
            },
            getDeviceType() {
                return this.deviceType;
            }
        },
        watch: {
            //侦听遥信数据
            telecommandMessage(message) {
                this.telecommandMessageInfo(message);
            },
            //侦听遥测数据
            telemeteringMessage(message){
                this.telemeteringMessageInfo(message);
            },
            getDeviceType: function () {
                this.deviceTypeChange();
            }
        },
        data() {
            return {
                dialogVisible:false,
                currentDeviceType: '001009',
                monitorType001001: null,
                monitorType013001: null,
                monitorType010004: null,
                monitorType010003: null,
                monitorType010005: null,
                monitorType001002: null,
            }
        },
        methods: {
            deviceTypeChange(){
                if (this.currentDeviceType == this.deviceType) {
                    this.dialogVisible = true;
                    this.initMonitorRedisData();
                }
            },
            initMonitorRedisData(){
                this.deviceInfo.monitorList.map(v =>{
                    this.findMonitorTypeValue(v.monitorType);
                })
            },
            findMonitorTypeValue(monitorType){
                let monitorObj = this.deviceInfo.monitorList.find((item)=>{
                    return item.monitorType == monitorType;
                })
                this["monitorType"+monitorType] = monitorObj.value;
            },
            findMonitorCodeValue(message){
                if(this.deviceInfo != null) {
                    let monitorObj = this.deviceInfo.monitorList.find((item) => {
                        return item.monitorCode == message.monitorCode;
                    })
                    if (monitorObj != undefined) {
                        this["monitorType" + monitorObj.monitorType] = message.value;
                    }
                }
            },
            closeDoubleDrainageDialog(){
                this.$emit('update:deviceType', null)
                this.dialogVisible = false;
            },
            telecommandMessageInfo(message){
                this.findMonitorCodeValue(message);
            },
            telemeteringMessageInfo(message){
                this.findMonitorCodeValue(message);
            }
        }
    }
</script>

<style scoped>
.doubleDrainageStyle{
    width:100%;
    height:308px;
    background:url("../../../../../../assets/images/fireElevator-one.png") no-repeat center center;
}
.fireElevator-high {
    position: absolute;
    left: 545px;
    top: 167px;
}
.fireElevator-auto{
    position: absolute;
    left: 545px;
    top: 250px;
}
.fh-blow-btn {
    display: inline-block;
    width: 70px;
    height: 28px;
    color: #fff;
    line-height: 28px;
    text-align: center;
    background-color: #3ba537;
}
.autoHand{
    background-color: #3190c6;
}
.stop{
    background-color: #afb9bb;
}
.fault{
    background-color: #f43d3d;
}
.blowdown-title {
    font-size: 18px;
    text-align: center;
    font-weight: 600;
    color: #000;
}
.fireElevator-b p>span {
    margin-right: 20px;
}
</style>
