var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.dialogVisible,
        width: "866px",
        top: "6vh",
        "before-close": _vm.closeAirUnitDialog,
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c(
        "template",
        { slot: "title" },
        [_c("title-icon"), _vm._v("空调机组\n    ")],
        1
      ),
      _c("div", { staticStyle: { width: "100%", height: "560px" } }, [
        _c("div", { staticClass: "modal-body fanAir" }, [
          _c("div", { staticClass: "cg-newFanOne" }, [
            _c("img", {
              staticStyle: { "vertical-align": "middle" },
              attrs: {
                src: require("../../../../../../assets/images/newFanOne.png"),
              },
            }),
          ]),
          _c("div", { staticClass: "cg-air_temperature_humidity" }, [
            _c("span", { staticClass: "fanAir-tit" }, [_vm._v("回风温湿度:")]),
            _c("span", { staticClass: "fanAir-count" }, [
              _c("span", [
                _vm._v(_vm._s(parseFloat(_vm.monitorType007001).toFixed(2))),
              ]),
              _c("span", [_vm._v("°C")]),
            ]),
            _c("span", { staticClass: "fanAir-count" }, [
              _c("span", [
                _vm._v(_vm._s(parseFloat(_vm.monitorType008001).toFixed(2))),
              ]),
              _c("span", [_vm._v("%RH")]),
            ]),
          ]),
          _c("div", { staticClass: "cg_air_temperature_co2" }, [
            _c("span", { staticClass: "fanAir-tit" }, [_vm._v("回风CO2:")]),
            _c("span", { staticClass: "fanAir-count" }, [
              _c("span", [
                _vm._v(_vm._s(parseFloat(_vm.monitorType009001).toFixed(2))),
              ]),
              _c("span", [_vm._v("ppm")]),
            ]),
          ]),
          _c("div", { staticClass: "cg_push_fan_tem" }, [
            _c("span", { staticClass: "fanAir-tit" }, [_vm._v("送风温度：")]),
            _c("span", { staticClass: "fanAir-count" }, [
              _c("span", [
                _vm._v(_vm._s(parseFloat(_vm.monitorType007002).toFixed(2))),
              ]),
              _c("span", [_vm._v("°C")]),
            ]),
          ]),
          _c(
            "div",
            { staticClass: "cg_filter_alarm-t" },
            [
              _c("span", { staticClass: "fanAir-tit" }, [_vm._v("滤网报警")]),
              _vm.monitorType010001 == "true"
                ? [
                    _c("span", { staticClass: "fanAir-btn fault" }, [
                      _vm._v("报警"),
                    ]),
                  ]
                : [_c("span", { staticClass: "fanAir-btn" }, [_vm._v("正常")])],
            ],
            2
          ),
          _c(
            "div",
            { staticClass: "cg_antifreeze_alarm-t" },
            [
              _c("span", { staticClass: "fanAir-tit" }, [_vm._v("防冻报警")]),
              _vm.monitorType010002 == "true"
                ? [
                    _c("span", { staticClass: "fanAir-btn fault" }, [
                      _vm._v("报警"),
                    ]),
                  ]
                : [_c("span", { staticClass: "fanAir-btn" }, [_vm._v("正常")])],
            ],
            2
          ),
          _c(
            "div",
            { staticClass: "cg_filter_alarm-b" },
            [
              _c("span", { staticClass: "fanAir-tit" }, [
                _vm._v("静电滤网状态"),
              ]),
              _vm.monitorType011001 == "true"
                ? [
                    _c("span", { staticClass: "fanAir-btn fault" }, [
                      _vm._v("报警"),
                    ]),
                  ]
                : [_c("span", { staticClass: "fanAir-btn" }, [_vm._v("正常")])],
            ],
            2
          ),
          _c(
            "div",
            { staticClass: "cg-fanAir-hand-auto-1" },
            [
              _c("span", { staticClass: "fanAir-tit" }, [_vm._v("风机压差")]),
              _vm.monitorType012001 == "true"
                ? [
                    _c("span", { staticClass: "fanAir-btn fault" }, [
                      _vm._v("报警"),
                    ]),
                  ]
                : [_c("span", { staticClass: "fanAir-btn" }, [_vm._v("正常")])],
            ],
            2
          ),
          _c(
            "div",
            { staticClass: "cg-fanAir-hand-auto-2" },
            [
              _c("span", { staticClass: "fanAir-tit" }, [_vm._v("手自动状态")]),
              _vm.monitorType013001 == "true"
                ? [
                    _c("span", { staticClass: "fanAir-btn autoHand" }, [
                      _vm._v("自动"),
                    ]),
                  ]
                : [
                    _c("span", { staticClass: "fanAir-btn autoHand" }, [
                      _vm._v("手动"),
                    ]),
                  ],
            ],
            2
          ),
          _c(
            "div",
            { staticClass: "cg-fanAir-hand-auto-3" },
            [
              _c("span", { staticClass: "fanAir-tit" }, [
                _vm._v("风机故障状态"),
              ]),
              _vm.monitorType010004 == "true"
                ? [
                    _c("span", { staticClass: "fanAir-btn fault" }, [
                      _vm._v("故障"),
                    ]),
                  ]
                : [_c("span", { staticClass: "fanAir-btn" }, [_vm._v("正常")])],
            ],
            2
          ),
          _c(
            "div",
            { staticClass: "cg-fanAir-hand-auto-4" },
            [
              _c("span", { staticClass: "fanAir-tit" }, [
                _vm._v("风机运行状态"),
              ]),
              _vm.monitorType001001 == "true"
                ? [_c("span", { staticClass: "fanAir-btn" }, [_vm._v("运行")])]
                : [
                    _c("span", { staticClass: "fanAir-btn stop" }, [
                      _vm._v("停止"),
                    ]),
                  ],
            ],
            2
          ),
        ]),
      ]),
      _c(
        "div",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary" },
              on: { click: _vm.closeAirUnitDialog },
            },
            [_vm._v(_vm._s(_vm.$t("commons.close")))]
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }