<template>
    <el-dialog
            :visible.sync="dialogVisible"
            width="60%"
            top="6vh"
            :before-close="closeHighVoltageDialog">
        <template slot="title">
            <title-icon/>高压进线柜
        </template>
        <div style="width: 100%;height:635px;">
            <div style="width: 49%;float: left;">
                <p>
                    <span>相电压平均值:<span>{{parseFloat(monitorType002007).toFixed(2)}}V</span></span>
                    <span style="margin: 40px;">相电流平均值:<span>{{parseFloat(monitorType003007).toFixed(2)}}A</span></span>
                    <span>频率:<span>{{parseFloat(monitorType006001).toFixed(2)}}HZ</span></span>
                </p>
                <table class="nh-one-table-l" style="width: 100%;border: 1px solid #eee;" border="1" cellspacing="0">
                    <thead>
                    <tr>
                        <th></th>
                        <th>相电压</th>
                        <th>相电流</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>A相</td>
                        <td>
                            <v-chart style="width: 200px; height: 174px;" :options="monitorType002001Options" theme="macarons"
                                     :auto-resize="true"></v-chart>
                        </td>
                        <td>
                            <v-chart style="width: 200px; height: 174px;" :options="monitorType003001Options" theme="macarons"
                                     :auto-resize="true"></v-chart>
                        </td>
                    </tr>
                    <tr>
                        <td>B相</td>
                        <td>
                            <v-chart style="width: 200px; height: 173px;" :options="monitorType002002Options" theme="macarons"
                                     :auto-resize="true"></v-chart>
                        </td>
                        <td>
                            <v-chart style="width: 200px; height: 173px;" :options="monitorType003002Options" theme="macarons"
                                     :auto-resize="true"></v-chart>
                        </td>
                    </tr>
                    <tr>
                        <td>C相</td>
                        <td>
                            <v-chart style="width: 200px; height: 173px;" :options="monitorType002003Options" theme="macarons"
                                     :auto-resize="true"></v-chart>
                        </td>
                        <td>
                            <v-chart style="width: 200px; height: 173px;" :options="monitorType003003Options" theme="macarons"
                                     :auto-resize="true"></v-chart>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <div style="width: 49%; margin-left:2%;float: left;">
                <p style="text-align: center;font-weight: 600;">功率</p>
                <div class="t">
                    <table class="nh-one-table-r-t" style="width: 100%;border: 1px solid #eee;" border="1" cellspacing="0">
                        <thead>
                        <tr>
                            <th></th>
                            <th>有功功率</th>
                            <th>无功功率</th>
                            <th>现有功率</th>
                            <th>功率因数</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>A相</td>
                            <td>{{parseFloat(monitorType004001).toFixed(2)}}</td>
                            <td>{{parseFloat(monitorType004005).toFixed(2)}}</td>
                            <td>{{parseFloat(monitorType004009).toFixed(2)}}</td>
                            <td>{{parseFloat(monitorType004013).toFixed(2)}}</td>
                        </tr>
                        <tr>
                            <td>B相</td>
                            <td>{{parseFloat(monitorType004002).toFixed(2)}}</td>
                            <td>{{parseFloat(monitorType004006).toFixed(2)}}</td>
                            <td>{{parseFloat(monitorType004010).toFixed(2)}}</td>
                            <td>{{parseFloat(monitorType004014).toFixed(2)}}</td>
                        </tr>
                        <tr>
                            <td>C相</td>
                            <td>{{parseFloat(monitorType004003).toFixed(2)}}</td>
                            <td>{{parseFloat(monitorType004007).toFixed(2)}}</td>
                            <td>{{parseFloat(monitorType004011).toFixed(2)}}</td>
                            <td>{{parseFloat(monitorType004015).toFixed(2)}}</td>
                        </tr>
                        <tr>
                            <td>三相</td>
                            <td>{{parseFloat(monitorType004004).toFixed(2)}}</td>
                            <td>{{parseFloat(monitorType004008).toFixed(2)}}</td>
                            <td>{{parseFloat(monitorType004012).toFixed(2)}}</td>
                            <td>{{parseFloat(monitorType004016).toFixed(2)}}</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <p style="text-align: center;font-weight: 600;">电度</p>
                <div class="c">
                    <table class="nh-one-table-r-b" style="width: 100%;border: 1px solid #eee;" border="1" cellspacing="0">
                        <tbody>
                        <tr>
                            <td>正向有功电度(Kwh)</td>
                            <td>{{parseFloat(monitorType005001).toFixed(2)}}</td>
                            <td>正向无功电度(Kvarh)</td>
                            <td>{{parseFloat(monitorType005002).toFixed(2)}}</td>
                        </tr>
                        <tr>
                            <td>反向有功电度(Kwh)</td>
                            <td>{{parseFloat(monitorType005003).toFixed(2)}}</td>
                            <td>反向无功电度(Kvarh)</td>
                            <td>{{parseFloat(monitorType005004).toFixed(2)}}</td>
                        </tr>
                        <tr>
                            <td>有功电度总量(Kwh)</td>
                            <td>{{parseFloat(monitorType005005).toFixed(2)}}</td>
                            <td>无功电度总量(Kvarh)</td>
                            <td>{{parseFloat(monitorType005006).toFixed(2)}}</td>
                        </tr>
                        <tr>
                            <td>有功电度净值(Kwh)</td>
                            <td>{{parseFloat(monitorType005007).toFixed(2)}}</td>
                            <td>无功电度净值(Kvarh)</td>
                            <td>{{parseFloat(monitorType005008).toFixed(2)}}</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <p style="text-align: center;font-weight: 600;">电流电压</p>
                <div class="b">
                    <table class="nh-one-table-r-b" style="width: 100%;border: 1px solid #eee;" border="1" cellspacing="0">
                        <tbody>
                        <tr>
                            <td>A相总谐波电流畸变率</td>
                            <td>{{parseFloat(monitorType003004).toFixed(2)}}</td>
                            <td>A相总谐波电压畸变率</td>
                            <td>{{parseFloat(monitorType002004).toFixed(2)}}</td>
                        </tr>
                        <tr>
                            <td>B相总谐波电流畸变率</td>
                            <td>{{parseFloat(monitorType003005).toFixed(2)}}</td>
                            <td>B相总谐波电压畸变率</td>
                            <td>{{parseFloat(monitorType002005).toFixed(2)}}</td>
                        </tr>
                        <tr>
                            <td>C相总谐波电流畸变率</td>
                            <td>{{parseFloat(monitorType003006).toFixed(2)}}</td>
                            <td>C相总谐波电压畸变率</td>
                            <td>{{parseFloat(monitorType002006).toFixed(2)}}</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div slot="footer">
            <el-button type="primary" @click="closeHighVoltageDialog">{{$t('commons.close')}}</el-button>
        </div>
    </el-dialog>
</template>

<script>
    // 导入主题，引用时在v-chart中增加theme="macarons"
    import 'echarts/theme/macarons'

    export default {
        name: 'HighVoltage',
        props: {
            // //是否显示
            // dialogVisible: {
            //     type: Boolean
            // },
            // 报警信息
            deviceInfo: {
                type: Object,
            },
            // 设备类型编码
            deviceType: {
                type: String,
            },
        },
        computed: {
            telecommandMessage() {
                return this.$store.getters.telecommandMessage;
            },
            telemeteringMessage(){
                return this.$store.getters.telemeteringMessage;
            },
            getDeviceType() {
                return this.deviceType;
            }
        },
        watch: {
            //侦听遥信数据
            telecommandMessage(message) {
                this.telecommandMessageInfo(message);
            },
            //侦听遥测数据
            telemeteringMessage(message){
                this.telemeteringMessageInfo(message);
            },
            getDeviceType: function () {
                this.deviceTypeChange();
            }
        },
        data() {
            return {
                dialogVisible:false,
                currentDeviceType: '001007',
                monitorType002001: null,
                monitorType002002: null,
                monitorType002003: null,
                monitorType003001: null,
                monitorType003002: null,
                monitorType003003: null,
                monitorType004001: null,
                monitorType004002: null,
                monitorType004003: null,
                monitorType004004: null,
                monitorType004005: null,
                monitorType004006: null,
                monitorType004007: null,
                monitorType004008: null,
                monitorType004009: null,
                monitorType004010: null,
                monitorType004011: null,
                monitorType004012: null,
                monitorType004013: null,
                monitorType004014: null,
                monitorType004015: null,
                monitorType004016: null,
                monitorType005001: null,
                monitorType005002: null,
                monitorType005003: null,
                monitorType005004: null,
                monitorType005005: null,
                monitorType005006: null,
                monitorType005007: null,
                monitorType005008: null,
                monitorType003004: null,
                monitorType003005: null,
                monitorType003006: null,
                monitorType002004: null,
                monitorType002005: null,
                monitorType002006: null,
                monitorType002007: null,
                monitorType003007: null,
                monitorType006001: null,
                monitorType002001Options:{},
                monitorType002002Options:{},
                monitorType002003Options:{},
                monitorType003001Options:{},
                monitorType003002Options:{},
                monitorType003003Options:{},
            }
        },
        methods: {
            deviceTypeChange(){
                if (this.currentDeviceType == this.deviceType) {
                    this.dialogVisible = true;
                    this.initMonitorRedisData();
                }
            },
            initMonitorRedisData(){
                this.deviceInfo.monitorList.map(v =>{
                    this.findMonitorTypeValue(v.monitorType);

                    if(v.monitorType == "002001" || v.monitorType =="002002" || v.monitorType =="002003"){
                        this["monitorType"+v.monitorType+"Options"] = this.dashboardOptions(v.monitorName,v.monitorType,'V');
                    }
                    if(v.monitorType == "003001" || v.monitorType =="003002" || v.monitorType =="003003"){
                        this["monitorType"+v.monitorType+"Options"] = this.dashboardOptions(v.monitorName,v.monitorType,'A');
                    }
                })
            },
            findMonitorTypeValue(monitorType){
                let monitorObj = this.deviceInfo.monitorList.find((item)=>{
                    return item.monitorType == monitorType;
                })
                this["monitorType"+monitorType] = monitorObj.value;
            },
            findMonitorCodeValue(message){
                if(this.deviceInfo != null) {
                    let monitorObj = this.deviceInfo.monitorList.find((item) => {
                        return item.monitorCode == message.monitorCode;
                    })
                    if (monitorObj != undefined) {
                        this["monitorType" + monitorObj.monitorType] = message.value;

                        if(monitorObj.monitorType == "002001" || monitorObj.monitorType == "002002" || monitorObj.monitorType == "002003" ){
                            this["monitorType"+monitorObj.monitorType+"Options"] = this.dashboardOptions(monitorObj.monitorName,monitorObj.monitorType,'V');
                        }
                        if(monitorObj.monitorType == "003001" || monitorObj.monitorType == "003002" || monitorObj.monitorType == "003003" ){
                            this["monitorType"+monitorObj.monitorType+"Options"] = this.dashboardOptions(monitorObj.monitorName,monitorObj.monitorType,'A');
                        }
                    }
                }
            },
            closeHighVoltageDialog(){
                this.$emit('update:deviceType', null)
                this.dialogVisible = false;
            },
            telecommandMessageInfo(message){
                this.findMonitorCodeValue(message);
            },
            telemeteringMessageInfo(message){
                this.findMonitorCodeValue(message);
            },
            dashboardOptions(monitorName,monitorType,symbol){
                return {
                    tooltip : {
                        formatter: "{a} <br/>{b} : {c}V"
                    },
                    series: [
                        {
                            name: monitorName,
                            type: 'gauge',
                            center: ['60%', '50%'],
                            min: 0,
                            max: 300,
                            splitNumber: 4,
                            radius: '80%',
                            axisLine: { // 坐标轴线
                                lineStyle: { // 属性lineStyle控制线条样式
                                    color: [
                                        [0.2, '#e4ac55'],
                                        [0.8, '#42c68c'],
                                        [1, '#e35151']
                                    ],
                                    width: 10,
                                    shadowColor: '#fff', //默认透明
                                    shadowBlur: 10
                                }
                            },
                            axisLabel: { // 坐标轴小标记
                                textStyle: {
                                    color: '#666',
                                    fontSize:10,
                                    shadowColor: '#fff', //默认透明
                                    shadowBlur: 10
                                }
                            },
                            splitLine: { // 分隔线
                                length:8, // 属性length控制线长
                                lineStyle: {
                                    width: 1,
                                    color: '#fff',
                                    shadowColor: '#fff', //默认透明
                                    shadowBlur: 10
                                }
                            },
//					指针
                            pointer: {
                                width: 2,
                                shadowColor: '#e7a954', //默认透明
                                shadowBlur: 5
                            },
                            detail: {
                                fontSize:14,
                                color:"#333",
                                offsetCenter:[0,'90%'],
                                formatter:'{value}'+symbol
                            },
//					数据
                            data: [{
                                value: parseFloat(this["monitorType"+monitorType]).toFixed(2),
                                name: symbol
                            }]
                        }
                    ]
                };
            },
        }
    }
</script>

<style scoped>
table td, table th {
    border: 1px solid #eee;
    height: 40px;
    text-align: center;
    font-weight: normal;
}
</style>
