<template>
    <div class="app-container-empty">
        <map-exhibition :positionId="positionId" :categoryId="categoryId" :height="height" :toolTop="toolTop" :toolRight="toolRight" :minZoom="minZoom" :maxZoom="maxZoom" :autoChange="autoChange"/>
    </div>
</template>

<script>
    import {MapExhibition} from '@/views/ruge/iot/map/components'

    export default {
        name: "Exhibition",
        components:{
            MapExhibition
        },
        data() {
            return {
                positionId: null,
                categoryId: null,
                height: undefined,
                toolTop: null,
                toolRight: null,
                minZoom:null,
                maxZoom:null,
                autoChange:null,
            }
        },
        created(){
            this.initMapParams();
        },
        watch:{
            '$route'(to,from){
                if(to.query != from.query){
                    this.initMapParams();
                }
            }
        },
        methods:{
            initMapParams(){
                let positionId = this.$route.query.positionId;
                if(positionId != undefined){
                    this.positionId = Number(positionId);
                }else{
                    this.positionId = null;
                }

                let categoryId = this.$route.query.categoryId;
                if(categoryId != undefined){
                    this.categoryId = Number(categoryId);
                }else{
                    this.categoryId = null;
                }

                this.height = this.$route.query.height;

                let toolTop = this.$route.query.toolTop;
                if(toolTop != undefined){
                    this.toolTop = Number(toolTop);
                }else{
                    this.toolTop = 1;
                }

                let toolRight = this.$route.query.toolRight;
                if(toolRight != undefined){
                    this.toolRight = Number(toolRight);
                }else{
                    this.toolRight = 1;
                }

                let minZoom = this.$route.query.minZoom;
                if(minZoom != undefined){
                    this.minZoom = Number(minZoom);
                }else{
                    this.minZoom = 50;
                }

                let maxZoom = this.$route.query.maxZoom;
                if(maxZoom != undefined){
                    this.maxZoom = Number(maxZoom);
                }else{
                    this.maxZoom = 200;
                }

                let autoChange = this.$route.query.autoChange;
                if(autoChange != undefined){
                    this.autoChange = Number(autoChange);
                }else{
                    this.autoChange = 0;
                }
            }
        }
    }
</script>

<style scoped>

</style>
