var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.dialogVisible,
        width: "60%",
        top: "6vh",
        "before-close": _vm.closeHighVoltageDialog,
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c(
        "template",
        { slot: "title" },
        [_c("title-icon"), _vm._v("高压进线柜\n    ")],
        1
      ),
      _c("div", { staticStyle: { width: "100%", height: "635px" } }, [
        _c("div", { staticStyle: { width: "49%", float: "left" } }, [
          _c("p", [
            _c("span", [
              _vm._v("相电压平均值:"),
              _c("span", [
                _vm._v(
                  _vm._s(parseFloat(_vm.monitorType002007).toFixed(2)) + "V"
                ),
              ]),
            ]),
            _c("span", { staticStyle: { margin: "40px" } }, [
              _vm._v("相电流平均值:"),
              _c("span", [
                _vm._v(
                  _vm._s(parseFloat(_vm.monitorType003007).toFixed(2)) + "A"
                ),
              ]),
            ]),
            _c("span", [
              _vm._v("频率:"),
              _c("span", [
                _vm._v(
                  _vm._s(parseFloat(_vm.monitorType006001).toFixed(2)) + "HZ"
                ),
              ]),
            ]),
          ]),
          _c(
            "table",
            {
              staticClass: "nh-one-table-l",
              staticStyle: { width: "100%", border: "1px solid #eee" },
              attrs: { border: "1", cellspacing: "0" },
            },
            [
              _c("thead", [
                _c("tr", [
                  _c("th"),
                  _c("th", [_vm._v("相电压")]),
                  _c("th", [_vm._v("相电流")]),
                ]),
              ]),
              _c("tbody", [
                _c("tr", [
                  _c("td", [_vm._v("A相")]),
                  _c(
                    "td",
                    [
                      _c("v-chart", {
                        staticStyle: { width: "200px", height: "174px" },
                        attrs: {
                          options: _vm.monitorType002001Options,
                          theme: "macarons",
                          "auto-resize": true,
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "td",
                    [
                      _c("v-chart", {
                        staticStyle: { width: "200px", height: "174px" },
                        attrs: {
                          options: _vm.monitorType003001Options,
                          theme: "macarons",
                          "auto-resize": true,
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _c("tr", [
                  _c("td", [_vm._v("B相")]),
                  _c(
                    "td",
                    [
                      _c("v-chart", {
                        staticStyle: { width: "200px", height: "173px" },
                        attrs: {
                          options: _vm.monitorType002002Options,
                          theme: "macarons",
                          "auto-resize": true,
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "td",
                    [
                      _c("v-chart", {
                        staticStyle: { width: "200px", height: "173px" },
                        attrs: {
                          options: _vm.monitorType003002Options,
                          theme: "macarons",
                          "auto-resize": true,
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _c("tr", [
                  _c("td", [_vm._v("C相")]),
                  _c(
                    "td",
                    [
                      _c("v-chart", {
                        staticStyle: { width: "200px", height: "173px" },
                        attrs: {
                          options: _vm.monitorType002003Options,
                          theme: "macarons",
                          "auto-resize": true,
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "td",
                    [
                      _c("v-chart", {
                        staticStyle: { width: "200px", height: "173px" },
                        attrs: {
                          options: _vm.monitorType003003Options,
                          theme: "macarons",
                          "auto-resize": true,
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ]),
            ]
          ),
        ]),
        _c(
          "div",
          { staticStyle: { width: "49%", "margin-left": "2%", float: "left" } },
          [
            _c(
              "p",
              { staticStyle: { "text-align": "center", "font-weight": "600" } },
              [_vm._v("功率")]
            ),
            _c("div", { staticClass: "t" }, [
              _c(
                "table",
                {
                  staticClass: "nh-one-table-r-t",
                  staticStyle: { width: "100%", border: "1px solid #eee" },
                  attrs: { border: "1", cellspacing: "0" },
                },
                [
                  _c("thead", [
                    _c("tr", [
                      _c("th"),
                      _c("th", [_vm._v("有功功率")]),
                      _c("th", [_vm._v("无功功率")]),
                      _c("th", [_vm._v("现有功率")]),
                      _c("th", [_vm._v("功率因数")]),
                    ]),
                  ]),
                  _c("tbody", [
                    _c("tr", [
                      _c("td", [_vm._v("A相")]),
                      _c("td", [
                        _vm._v(
                          _vm._s(parseFloat(_vm.monitorType004001).toFixed(2))
                        ),
                      ]),
                      _c("td", [
                        _vm._v(
                          _vm._s(parseFloat(_vm.monitorType004005).toFixed(2))
                        ),
                      ]),
                      _c("td", [
                        _vm._v(
                          _vm._s(parseFloat(_vm.monitorType004009).toFixed(2))
                        ),
                      ]),
                      _c("td", [
                        _vm._v(
                          _vm._s(parseFloat(_vm.monitorType004013).toFixed(2))
                        ),
                      ]),
                    ]),
                    _c("tr", [
                      _c("td", [_vm._v("B相")]),
                      _c("td", [
                        _vm._v(
                          _vm._s(parseFloat(_vm.monitorType004002).toFixed(2))
                        ),
                      ]),
                      _c("td", [
                        _vm._v(
                          _vm._s(parseFloat(_vm.monitorType004006).toFixed(2))
                        ),
                      ]),
                      _c("td", [
                        _vm._v(
                          _vm._s(parseFloat(_vm.monitorType004010).toFixed(2))
                        ),
                      ]),
                      _c("td", [
                        _vm._v(
                          _vm._s(parseFloat(_vm.monitorType004014).toFixed(2))
                        ),
                      ]),
                    ]),
                    _c("tr", [
                      _c("td", [_vm._v("C相")]),
                      _c("td", [
                        _vm._v(
                          _vm._s(parseFloat(_vm.monitorType004003).toFixed(2))
                        ),
                      ]),
                      _c("td", [
                        _vm._v(
                          _vm._s(parseFloat(_vm.monitorType004007).toFixed(2))
                        ),
                      ]),
                      _c("td", [
                        _vm._v(
                          _vm._s(parseFloat(_vm.monitorType004011).toFixed(2))
                        ),
                      ]),
                      _c("td", [
                        _vm._v(
                          _vm._s(parseFloat(_vm.monitorType004015).toFixed(2))
                        ),
                      ]),
                    ]),
                    _c("tr", [
                      _c("td", [_vm._v("三相")]),
                      _c("td", [
                        _vm._v(
                          _vm._s(parseFloat(_vm.monitorType004004).toFixed(2))
                        ),
                      ]),
                      _c("td", [
                        _vm._v(
                          _vm._s(parseFloat(_vm.monitorType004008).toFixed(2))
                        ),
                      ]),
                      _c("td", [
                        _vm._v(
                          _vm._s(parseFloat(_vm.monitorType004012).toFixed(2))
                        ),
                      ]),
                      _c("td", [
                        _vm._v(
                          _vm._s(parseFloat(_vm.monitorType004016).toFixed(2))
                        ),
                      ]),
                    ]),
                  ]),
                ]
              ),
            ]),
            _c(
              "p",
              { staticStyle: { "text-align": "center", "font-weight": "600" } },
              [_vm._v("电度")]
            ),
            _c("div", { staticClass: "c" }, [
              _c(
                "table",
                {
                  staticClass: "nh-one-table-r-b",
                  staticStyle: { width: "100%", border: "1px solid #eee" },
                  attrs: { border: "1", cellspacing: "0" },
                },
                [
                  _c("tbody", [
                    _c("tr", [
                      _c("td", [_vm._v("正向有功电度(Kwh)")]),
                      _c("td", [
                        _vm._v(
                          _vm._s(parseFloat(_vm.monitorType005001).toFixed(2))
                        ),
                      ]),
                      _c("td", [_vm._v("正向无功电度(Kvarh)")]),
                      _c("td", [
                        _vm._v(
                          _vm._s(parseFloat(_vm.monitorType005002).toFixed(2))
                        ),
                      ]),
                    ]),
                    _c("tr", [
                      _c("td", [_vm._v("反向有功电度(Kwh)")]),
                      _c("td", [
                        _vm._v(
                          _vm._s(parseFloat(_vm.monitorType005003).toFixed(2))
                        ),
                      ]),
                      _c("td", [_vm._v("反向无功电度(Kvarh)")]),
                      _c("td", [
                        _vm._v(
                          _vm._s(parseFloat(_vm.monitorType005004).toFixed(2))
                        ),
                      ]),
                    ]),
                    _c("tr", [
                      _c("td", [_vm._v("有功电度总量(Kwh)")]),
                      _c("td", [
                        _vm._v(
                          _vm._s(parseFloat(_vm.monitorType005005).toFixed(2))
                        ),
                      ]),
                      _c("td", [_vm._v("无功电度总量(Kvarh)")]),
                      _c("td", [
                        _vm._v(
                          _vm._s(parseFloat(_vm.monitorType005006).toFixed(2))
                        ),
                      ]),
                    ]),
                    _c("tr", [
                      _c("td", [_vm._v("有功电度净值(Kwh)")]),
                      _c("td", [
                        _vm._v(
                          _vm._s(parseFloat(_vm.monitorType005007).toFixed(2))
                        ),
                      ]),
                      _c("td", [_vm._v("无功电度净值(Kvarh)")]),
                      _c("td", [
                        _vm._v(
                          _vm._s(parseFloat(_vm.monitorType005008).toFixed(2))
                        ),
                      ]),
                    ]),
                  ]),
                ]
              ),
            ]),
            _c(
              "p",
              { staticStyle: { "text-align": "center", "font-weight": "600" } },
              [_vm._v("电流电压")]
            ),
            _c("div", { staticClass: "b" }, [
              _c(
                "table",
                {
                  staticClass: "nh-one-table-r-b",
                  staticStyle: { width: "100%", border: "1px solid #eee" },
                  attrs: { border: "1", cellspacing: "0" },
                },
                [
                  _c("tbody", [
                    _c("tr", [
                      _c("td", [_vm._v("A相总谐波电流畸变率")]),
                      _c("td", [
                        _vm._v(
                          _vm._s(parseFloat(_vm.monitorType003004).toFixed(2))
                        ),
                      ]),
                      _c("td", [_vm._v("A相总谐波电压畸变率")]),
                      _c("td", [
                        _vm._v(
                          _vm._s(parseFloat(_vm.monitorType002004).toFixed(2))
                        ),
                      ]),
                    ]),
                    _c("tr", [
                      _c("td", [_vm._v("B相总谐波电流畸变率")]),
                      _c("td", [
                        _vm._v(
                          _vm._s(parseFloat(_vm.monitorType003005).toFixed(2))
                        ),
                      ]),
                      _c("td", [_vm._v("B相总谐波电压畸变率")]),
                      _c("td", [
                        _vm._v(
                          _vm._s(parseFloat(_vm.monitorType002005).toFixed(2))
                        ),
                      ]),
                    ]),
                    _c("tr", [
                      _c("td", [_vm._v("C相总谐波电流畸变率")]),
                      _c("td", [
                        _vm._v(
                          _vm._s(parseFloat(_vm.monitorType003006).toFixed(2))
                        ),
                      ]),
                      _c("td", [_vm._v("C相总谐波电压畸变率")]),
                      _c("td", [
                        _vm._v(
                          _vm._s(parseFloat(_vm.monitorType002006).toFixed(2))
                        ),
                      ]),
                    ]),
                  ]),
                ]
              ),
            ]),
          ]
        ),
      ]),
      _c(
        "div",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary" },
              on: { click: _vm.closeHighVoltageDialog },
            },
            [_vm._v(_vm._s(_vm.$t("commons.close")))]
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }