import request from '@/utils/request'
import { envInfo } from '@/constants/envInfo'
/**
 * 控制点位
 * @param params
 */
export function contorlPoint(params) {
    return request({
        url: envInfo.bgApp.apiPath + '/thing/device/set',
        method: 'post',
        data: params
    })
}