<template>
    <el-dialog
            :visible.sync="dialogVisible"
            width="626px"
            top="6vh"
            :before-close="closeExhaustFanDialog">
        <template slot="title">
            <title-icon/>排风机
        </template>
        <div style="width: 100%;height: 260px;">
            <div style="float: left;padding: 20px;width: 50%;">
                <img src="../../../../../../assets/images/speed-wind.png"/>
                <p>
                    <span style="margin-left: 50px">环境温度:</span>
                    <span>{{parseFloat(monitorType007003).toFixed(2)}}</span>
                    <span>°C</span>
                </p>
            </div>
            <div style="float: left;width: 50%;height: 260px;">
                <div class="collectingWell-c-r speedWind-c-r fl">
                    <div>
                        <span class="titleClass">风机压差</span>
                        <template v-if="monitorType012001 == 'true'">
                            <span class="statusClass fault">
                                报警
                            </span>
                        </template>
                        <template v-else>
                            <span class="statusClass">
                                正常
                            </span>
                        </template>
                    </div>
                    <div>
                        <span class="titleClass">运行状态</span>
                        <template v-if="monitorType001001 == 'true'">
                            <span class="statusClass">
                                运行
                            </span>
                        </template>
                        <template v-else>
                            <span class="statusClass active">
                                停止
                            </span>
                        </template>
                    </div>
                    <div>
                        <span class="titleClass">故障状态</span>
                        <template v-if="monitorType010004 == 'true'">
                            <span class="statusClass fault">
                                故障
                            </span>
                        </template>
                        <template v-else>
                            <span class="statusClass">
                                正常
                            </span>
                        </template>
                    </div>
                    <div>
                        <span class="titleClass">手自动</span>
                        <template v-if="monitorType013001 == 'true'">
                            <span class="statusClass autoHand">
                                自动
                            </span>
                        </template>
                        <template v-else>
                            <span class="statusClass autoHand">
                                手动
                            </span>
                        </template>
                    </div>
                    <div>
                        <span class="titleClass">启动控制</span>
                        <span :class="monitorType001001 == 'true'?'statusClass point':'statusClass point active'" @click="controlPoint('001001','true')">启动</span>
                        <span :class="monitorType001001 == 'true'?'statusClass point active':'statusClass point'" @click="controlPoint('001001','false')">停止</span>
                    </div>
                </div>
            </div>
        </div>
        <div slot="footer">
            <el-button type="primary" @click="closeExhaustFanDialog">{{$t('commons.close')}}</el-button>
        </div>
    </el-dialog>
</template>

<script>
    import { contorlPoint } from '@/api/ruge/iot/device/control'

    export default {
        name: 'ExhaustFan',
        props: {
            // //是否显示
            // dialogVisible: {
            //     type: Boolean
            // },
            // 设备信息
            deviceInfo: {
                type: Object,
            },
            // 设备类型编码
            deviceType: {
                type: String,
            },
        },
        computed: {
            telecommandMessage() {
                return this.$store.getters.telecommandMessage;
            },
            telemeteringMessage(){
                return this.$store.getters.telemeteringMessage;
            },
            getDeviceType() {
                return this.deviceType;
            }
        },
        watch: {
            //侦听遥信数据
            telecommandMessage(message) {
                this.telecommandMessageInfo(message);
            },
            //侦听遥测数据
            telemeteringMessage(message){
                this.telemeteringMessageInfo(message);
            },
            getDeviceType: function () {
                this.deviceTypeChange();
            }
        },
        data() {
            return {
                dialogVisible:false,
                currentDeviceType: '001011',
                monitorType001001: null,
                monitorType012001: null,
                monitorType013001: null,
                monitorType010004: null,
                monitorType007003: null,
            }
        },
        methods: {
            deviceTypeChange(){
                if (this.currentDeviceType == this.deviceType) {
                    this.dialogVisible = true;
                    this.initMonitorRedisData();
                }
            },
            initMonitorRedisData(){
                this.deviceInfo.monitorList.map(v =>{
                    this.findMonitorTypeValue(v.monitorType);
                })
            },
            findMonitorTypeValue(monitorType){
                let monitorObj = this.deviceInfo.monitorList.find((item)=>{
                    return item.monitorType == monitorType;
                })
                this["monitorType"+monitorType] = monitorObj.value;
            },
            findMonitorCodeValue(message){
                if(this.deviceInfo != null) {
                    let monitorObj = this.deviceInfo.monitorList.find((item) => {
                        return item.monitorCode == message.monitorCode;
                    })
                    if (monitorObj != undefined) {
                        this["monitorType" + monitorObj.monitorType] = message.value;
                    }
                }
            },
            closeExhaustFanDialog(){
                this.$emit('update:deviceType', null)
                this.dialogVisible = false;
            },
            //遥信
            telecommandMessageInfo(message){
                this.findMonitorCodeValue(message);
            },
            //遥测
            telemeteringMessageInfo(message){
                this.findMonitorCodeValue(message);
            },
            controlPoint(monitorType,value){
                if(this.deviceInfo != null){
                    let monitorObj = this.deviceInfo.monitorList.find((item)=>{
                        return item.monitorType == monitorType;
                    })
                    contorlPoint({"monitorCode":monitorObj.monitorCode,"value":value});
                }
            }
        }
    }
</script>

<style scoped>
.titleClass {
    display: inline-block;
    height: 28px;
    line-height: 28px;
    min-width: 103px;
    text-align: right;
    margin-right: 10px;
}
.statusClass {
    display: inline-block;
    width: 75px;
    height: 28px;
    text-align: center;
    line-height: 28px;
    color: #fff;
    background-color: #20ebb6;
    margin-right: 10px;
}
.collectingWell-c-r>div {
    height: 28px;
    margin-bottom: 20px;
}
.active{
    background-color: #bcd0d5;
}
.autoHand{
    background-color: #3190c6;
}
.fault{
    background-color: #f43d3d;
}
.point{
    cursor: pointer;
}
</style>
