<template>
    <div :style="styles.mapContainer">
        <el-row>
            <el-col>
                <el-card class="downCard" v-loading="mapInitLoading">
                    <template v-if="toolTop == 1">
                        <div slot="header" class="cardHeader">
                            <el-row>
                                <el-col :span="15">
                                    <title-icon/>
                                    <template v-for="(item,index) in positionLabel">
                                        <span :key="index" class="labelSpan">
                                            <template v-if="positionLabel.length - 1 == index">
                                                <span>{{item.positionName}}</span>
                                            </template>
                                            <template v-else-if="0 == index">
                                                <span>{{item.positionName}}</span> >
                                            </template>
                                            <template v-else>
                                                <a @click="changeMap(item.positionId)">{{item.positionName}}</a> >
                                            </template>
                                        </span>
                                    </template>
                                </el-col>
                                <el-col :span="9" style="display: flex">
                                    <el-tooltip :content="$t('iot.map.position')">
                                        <el-button type="primary" icon="el-icon-position" @click="drawer = true"></el-button>
                                    </el-tooltip>
                                    <el-tooltip :content="$t('commons.refresh')">
                                        <el-button @click="refreshMap" type="success" icon="el-icon-refresh"></el-button>
                                    </el-tooltip>
                                    <el-radio-group v-model="radioTab" style="margin-left: 10px;display: flex;">
                                        <el-tooltip :content="$t('iot.map.mapView')">
                                            <el-radio-button label="1"><i class="el-icon-map-location"></i></el-radio-button>
                                        </el-tooltip>
                                        <el-tooltip :content="$t('iot.map.listView')">
                                            <el-radio-button label="2"><i class="el-icon-s-operation"></i></el-radio-button>
                                        </el-tooltip>
                                    </el-radio-group>
                                </el-col>
                            </el-row>
                        </div>
                    </template>
                    <div :style="styles.mapBody">
                        <template v-if="toolRight == 1">
                            <div style="position: absolute;width: 150px;height: 458px;z-index: 999;right: 20px;top:calc(50% - 200px);">
                                <el-row>
                                    <el-col>
                                        <i class="el-icon-arrow-up toolRight" @click="reduceIndex"></i>
                                    </el-col>
                                </el-row>
                                <el-row style="height: 335px;">
                                    <el-col>
                                        <div class = "toolUl">
                                            <template v-for="(position,index) in brotherPositionList">
                                                <template v-if="index>= (positionPage-1)*6 && index < positionPage*6">
                                                    <a class="liA" :key="index">
                                                            <span :class="position.positionId == presentPositionId ? 'liItemActive' : 'liItem'" @click="changeMap(position.positionId)">
                                                                {{position.positionName}}
                                                            </span>
                                                    </a>
                                                </template>
                                            </template>
                                        </div>
                                    </el-col>
                                </el-row>
                                <el-row>
                                    <el-col>
                                        <i class="el-icon-arrow-down toolRight" @click="addIndex"></i>
                                    </el-col>
                                </el-row>
                                <el-row style="margin-top: 10px">
                                    <el-col>
                                        <i class="el-icon-circle-plus-outline toolRight" :title="$t('iot.map.scaleUp')" @click="enlargePosition"></i>
                                    </el-col>
                                </el-row>
                                <el-row style="margin-top: 6px">
                                    <el-col>
                                        <i class="el-icon-remove-outline toolRight" :title="$t('iot.map.scaleDown')" @click="narrowPosition"></i>
                                    </el-col>
                                </el-row>
                            </div>
                        </template>
                        <template v-if="isHaveMap">
                            <template v-if="radioTab == 1">
                                <div @mousewheel="rollImg($event)" ref="mapScroll" :style="{width:'calc(100%)',height:'calc(100%)',overflow:'hidden'}">
                                    <vue-drag-resize :isResizable="false" :style="{width:mapWidth+'px',height:mapHeight+'px',position:'relative',zoom:mapZoom+'%'}">
                                        <template v-if="presentPositionId != null">
                                            <el-image :src="getImage(map.form.mapImageId)" @error="mapLoadError" ref="mapBackGround" @load="updateMapSize" :style="{cursor:'move'}">
                                                <div slot="placeholder" class="no-data">
                                                    {{ $t('iot.map.loading') }}<span class="dot">...</span>
                                                </div>
                                            </el-image>

                                            <template v-for="(device,index) in mapDeviceList">
                                                <template v-if="presentCategoryId==null || presentCategoryId == device.categoryId">
                                                    <vue-drag-resize :key="index" :isDraggable="false" :isResizable="false" v-on:clicked="deviceDetails(device)" :title="device.deviceName" :parentLimitation="true" :w="50" :h="50" :x="device.deviceLocationX" :y="device.deviceLocationY" ref="blockSon">
                                                        <div class="bubble_box">
                                                            <template v-for="(monitor,index) in device.monitorList">
                                                                <template v-if="monitor.isShow == 1">
                                                                    <span :key="index" class="bubble_bot_cor">
                                                                    </span>
                                                                    <p :key="monitor.monitorCode" style="white-space: nowrap;">
                                                                        <span>{{monitor.monitorName}}</span>
                                                                        <span>|</span>
                                                                        <span>
                                                                                <template v-if="monitor.value == 'true' || monitor.value == 'false'">
                                                                                    <template v-if="monitor.monitorType.startsWith('001')">
                                                                                        <template v-if="monitor.value == 'true'">
                                                                                            {{$t('iot.map.running')}}
                                                                                        </template>
                                                                                        <template v-else>
                                                                                            {{$t('iot.map.stop')}}
                                                                                        </template>
                                                                                    </template>
                                                                                    <template v-if="monitor.monitorType.startsWith('010') || monitor.monitorType.startsWith('011') || monitor.monitorType.startsWith('012')">
                                                                                        <template v-if="monitor.value == 'true'">
                                                                                            {{$t('iot.map.alarm')}}
                                                                                        </template>
                                                                                        <template v-else>
                                                                                            {{$t('iot.map.normal')}}
                                                                                        </template>
                                                                                    </template>
                                                                                    <!--<template v-if="monitor.monitorType.startsWith('010')">-->
                                                                                        <!--<template v-if="monitor.value == 'true'">-->
                                                                                            <!--{{$t('iot.map.fault')}}-->
                                                                                        <!--</template>-->
                                                                                        <!--<template v-else>-->
                                                                                            <!--{{$t('iot.map.normal')}}-->
                                                                                        <!--</template>-->
                                                                                    <!--</template>-->
                                                                                    <template v-if="monitor.monitorType.startsWith('013')">
                                                                                        <template v-if="monitor.value == 'true'">
                                                                                            {{$t('iot.map.auto')}}
                                                                                        </template>
                                                                                        <template v-else>
                                                                                            {{$t('iot.map.manual')}}
                                                                                        </template>
                                                                                    </template>
                                                                                </template>
                                                                                <template v-else>
                                                                                    {{parseFloat(monitor.value).toFixed(2)}}
                                                                                </template>
                                                                                {{monitor.symbol}}
                                                                        </span>
                                                                    </p>
                                                                </template>
                                                            </template>
                                                        </div>
                                                        <template v-if="device.onOff == 'true'">
                                                            <el-image :src="getImage(device.startPhoto)" :class="device.ifAlarm == 1 ? 'magentaPulse button':''" :style="{position: 'absolute',cursor:'pointer'}" :onerror="defaultDeviceIcon">
                                                                <div slot="placeholder" class="no-data">
                                                                    {{ $t('iot.map.loading') }}<span class="dot">...</span>
                                                                </div>
                                                            </el-image>
                                                        </template>
                                                        <template v-else>
                                                            <el-image :src="getImage(device.stopPhoto)" :class="device.ifAlarm == 1 ? 'magentaPulse button':''" :style="{position: 'absolute',cursor:'pointer'}" :onerror="defaultDeviceIcon">
                                                                <div slot="placeholder" class="no-data">
                                                                    {{ $t('iot.map.loading') }}<span class="dot">...</span>
                                                                </div>
                                                            </el-image>
                                                        </template>
                                                    </vue-drag-resize>
                                                </template>
                                            </template>
                                        </template>
                                    </vue-drag-resize>
                                </div>
                            </template>
                            <template v-else>
                                <el-table
                                        :data="mapDeviceList"
                                        :row-class-name="showDeviceRow"
                                        >
                                    <el-table-column
                                            prop="deviceId"
                                            :label="$t('iot.device.number')">
                                    </el-table-column>
                                    <el-table-column
                                            prop="deviceName"
                                            :label="$t('iot.device.name')">
                                    </el-table-column>
                                    <el-table-column
                                            prop="categoryName"
                                            :label="$t('base.category.system')">
                                    </el-table-column>
                                    <el-table-column
                                            prop="onOff"
                                            :label="$t('iot.iot.map.runningState')">
                                        <template slot-scope="scope">
                                            <template v-if="scope.row.onOff == 'true'">
                                                {{$t("iot.map.running")}}
                                            </template>
                                            <template v-else>
                                                {{$t("iot.map.stop")}}
                                            </template>
                                        </template>
                                    </el-table-column>
                                    <el-table-column
                                            prop="ifAlarm"
                                            :label="$t('commons.status')">
                                        <template slot-scope="scope">
                                            <template v-if="scope.row.ifAlarm == 1">
                                                {{$t('iot.alarm.info')}}
                                            </template>
                                            <template v-else>
                                                {{$t('iot.alarm.normal')}}
                                            </template>
                                        </template>
                                    </el-table-column>
                                    <el-table-column
                                            label="操作">
                                        <template slot-scope="scope">
                                            <span v-for="(monitor,index) in scope.row.monitorList" :key="index">
                                                <template v-if="monitor.monitorType == '001001'">
                                                    <el-switch
                                                            @change="setOnOffPoint(monitor,scope.row.onOff!='true')"
                                                            :value="scope.row.onOff=='true'"  :disabled="monitor.monitorPath == null || monitor.monitorPath == ''">
                                                    </el-switch>
                                                </template>
                                            </span>
                                        </template>
                                    </el-table-column>
                                </el-table>
                            </template>
                        </template>
                        <template v-else>
                            <div class="no-data">{{$t('commons.noData')}}</div>
                        </template>
                    </div>
                </el-card>
            </el-col>
        </el-row>

        <el-drawer
                :visible.sync="drawer"
                :direction="direction"
                size="420px">
            <template slot="title">
                <span style="text-align: left;"><title-icon/>{{ $t('iot.map.position') }}</span>
            </template>
            <el-form label-position="top" style="padding: 0px 25px;">
                <el-form-item :label="$t('iot.project.name')">
                    <el-select @change="changeProject" v-model="presentProjectId" filterable clearable :style="{width:'100%'}">
                        <el-option
                                v-for="item in projectList"
                                :key="item.projectId"
                                :value="item.projectId"
                                :label="item.projectName">
                        </el-option>
                    </el-select>
                </el-form-item>

                <el-form-item :label="$t('iot.position.name')">
                    <tree-select
                            v-model="presentPositionId"
                            :multiple="false"
                            :show-count="true"
                            :disable-branch-nodes="true"
                            :placeholder="$t('commons.selectPlease')"
                            :options="positionList"
                            @input="changePosition"/>
                </el-form-item>
            </el-form>
        </el-drawer>

        <!--摄像头弹窗-->
        <el-dialog :visible.sync="camera.playVisible" width="50%" top="6vh">
            <template slot="title">
                <title-icon/>{{ $t('commons.play') }}
            </template>
            <div style="height:75vh;">
                <video ref="video" style="width:100%;"></video>
            </div>
            <span slot="footer">
                 <el-button type="primary" @click="cancelPlay">{{$t('commons.close') }}</el-button>
             </span>
        </el-dialog>

        <component
                v-for="comp in comps"
                :deviceInfo="deviceInfo"
                :deviceType.sync="deviceType"
                :key="comp.id"
                :is="allComps[comp]">
        </component>
    </div>
</template>

<script>
    import { getPositionMap, getMapDeviceList } from '@/api/ruge/iot/map/map'
    import { contorlPoint } from '@/api/ruge/iot/device/control'
    import { findDeviceById } from '@/api/ruge/iot/device/device'
    import { getPositionById, getPositionTree } from '@/api/ruge/iot/position/position'
    import { getProjectList } from '@/api/ruge/iot/project/project'
    import TreeSelect from '@riophae/vue-treeselect';
    import '@riophae/vue-treeselect/dist/vue-treeselect.css'
    import {listToTree} from '@/utils/tree'
    import { envInfo } from '@/constants/envInfo'
    import store from '@/store'

    import allComps from '@/views/ruge/iot/map/components/details'

    let media =  require('@/utils/media.js')

    export default {
        name: "MapExhibition",
        props: {
            // 当前位置ID
            positionId: {
                type: Number
            },
            // 当前所属系统
            categoryId: {
                type: Number
            },
            // 上部工具栏是否显示
            toolTop: {
                type: Number,
            },
            // 右部工具栏是否显示
            toolRight: {
                type: Number,
            },
            // 宽度
            width: {
                type: String,
                default: '100%'
            },
            // 高度
            height: {
                type: String,
                default: '100vh - 140px'
            },
            //最小缩小比例
            minZoom: {
                type: Number,
                default: 50
            },
            //最大放大比例
            maxZoom: {
                type: Number,
                default: 200
            },
            //是否可放大缩小地图
            autoChange: {
                type: Number,
                default: 0
            }
        },
        components:{
            TreeSelect,
            // HighVoltage,
            // LowVoltage,
            // DoubleDrainage,
            // ExhaustFan,
            // AirUnit
        },
        computed: {
            alarmMessage() {
                return this.$store.getters.alarmMessage;
            },
            telecommandMessage() {
                return this.$store.getters.telecommandMessage;
            },
            telemeteringMessage(){
                return this.$store.getters.telemeteringMessage;
            },
            positionParamChange() {
                return this.positionId;
            },
            categoryParamChange() {
                return this.categoryId;
            },
            heightParamChange() {
                return this.categoryId;
            },
        },
        watch: {
            //侦听遥信数据
            alarmMessage(message) {
                this.alarmMessageInfo(message);
            },
            //侦听遥信数据
            telecommandMessage(message) {
                this.telecommandMessageInfo(message);
            },
            //侦听遥测数据
            telemeteringMessage(message){
                this.telemeteringMessageInfo(message);
            },
            positionParamChange() {
                this.initPositionMap();
            },
            categoryParamChange() {
                this.presentCategoryId = this.categoryId;
            },
            // 侦听高度参数设置，发生变化时，重新设置高度
            heightParamChange() {
                this.styles.mapBody.height = `calc(${this.height})`;
            }
        },
        data() {
            return {
                comps: ['highVoltage', 'lowVoltage', 'doubleDrainage','exhaustFan','airUnit'],
                allComps:allComps,
                styles : {
                    mapContainer: {
                        width: `calc(${this.width})`
                    },
                    mapBody: {
                        width: `calc(100%)`,
                        height: `calc(${this.height})`
                    }
                },
                // 默认设备图标
                defaultDeviceIcon: 'this.src="' + require("@/assets/images/monitor.png") + '"',
                mapInitLoading:true,

                deviceInfo:null,
                deviceType:null,

                listLoading:false,
                drawer:false,
                direction: 'rtl',
                //1为地图2为表格
                radioTab:1,
                presentProjectId:null,
                presentPositionId:null,
                presentCategoryId:null,
                presentNode:null,
                positionList:[],
                projectList:[],
                //兄弟节点位置
                brotherPositionList:[],
                //位置当前页
                positionPage:1,
                positionLabel:[{positionName:this.$t('iot.map.display')}],
                //当前位置是否关联地图
                isHaveMap:false,
                //地图中的设备
                mapDeviceList:[],
                positionMap:[],
                mapWidth:360,
                mapHeight:180,
                mapZoom:100,
                deviceMap:{
                    form:{
                        mapDeviceId :null,
                        mapId:null,
                        deviceId:null,
                        deviceName:null,
                        categoryName:null,
                        deviceLocationX:null,
                        deviceLocationY:null,
                        onOff:null,
                        monitorCode:null,
                        startPhoto:null,
                        stopPhoto:null,
                        categoryId:null,
                        deviceType:null,
                        monitorType:null,
                        monitorLevel:null,
                        isAuto:null,
                        ifAlarm:null,
                        monitorPath:null,
                        positionId:null,
                        monitorList:null,
                    }
                },
                map:{
                    form:{
                        mapId:null,
                        mapImageId:null,
                        positionId:null
                    }
                },
                //摄像头
                camera: {
                    playVisible: false,
                }
            }
        },
        created(){
            this.findProjectList().then(() =>{
                this.initPositionMap();
            });
        },
        methods:{
            initPositionMap(){
                // 加载地图位置参数
                if(this.positionId != null){
                    getPositionById({"positionId": this.positionId}).then((res)=>{
                        this.presentProjectId = res.projectId;
                        this.changeProject().then(()=>{
                            this.presentPositionId = res.positionId;
                            this.changeMap(this.positionId).then(()=>{
                                this.mapInitLoading = false;
                            })
                        });
                    });
                }else{
                    this.mapInitLoading = false;
                }
            },
            findProjectList(){
                return getProjectList().then(response =>{
                    this.projectList = response;
                });
            },
            changeProject() {
                if(this.presentProjectId == ''){
                    this.presentProjectId = null;
                    this.presentPositionId = null;
                    this.positionList = [];
                }else{
                    return getPositionTree({"projectId":this.presentProjectId}).then(response =>{
                        this.positionList = [];
                        this.presentPositionId = null;
                        let obj = this.projectList.find((item)=>{
                            return item.projectId === this.presentProjectId;//筛选出匹配数据
                        });
                        this.positionMap = new Map();
                        response.push({"positionId":-1,"positionName":obj.projectName})
                        let data = listToTree(response,"positionId","parentId");
                        this.recursivePosition(data)
                    });
                }
            },
            //递归修改属性及数据结构
            recursivePosition(row) {
                let data = row.map(v => {
                    this.positionMap.set(v.positionId,v)
                    v.label = v.positionName;
                    v.id = v.positionId;
                    if(v.children != undefined){
                        this.recursivePosition(v.children);
                    }
                    return v
                })
                this.positionList = data;
            },
            changePosition(){
                if(this.presentPositionId != undefined){
                    this.changeMap(this.presentPositionId);
                }
            },
            //设备列表行隐藏样式
            showDeviceRow({row}){
                if(this.presentCategoryId == row.categoryId || this.presentCategoryId == null){
                    return;
                }else{
                    return "mapDeviceHidden";
                }
            },
            //地图加载错误回调
            mapLoadError(){
                this.mapWidth=1822;
                this.mapHeight= 568;
            },
            //获取图片
            getImage(fileId){
                if(!fileId) {
                    return "";
                }
                return envInfo.bgApp.archivePath + "/param/archive/download?dlType=DefaultDownload&fType=image&fi=" + fileId;
            },
            //地图加载完成回调
            updateMapSize(){
                let obj = this.$refs;
                if(obj.mapBackGround != undefined){
                    let mapWidth = obj.mapBackGround.imageWidth;
                    let mapHeight = obj.mapBackGround.imageHeight;
                    this.mapWidth = mapWidth;
                    this.mapHeight = mapHeight;
                }
            },
            changeMap(positionId){
                let positionNode = this.positionMap.get(positionId);
                if(positionNode && positionNode.parentId != -999){
                    //this.positionCollapse = false;
                    this.positionLabel = [positionNode];
                    this.findParentName(positionNode);
                    this.positionLabel = this.positionLabel.reverse();
                    this.mapInitLoading = true;
                    //获取地图信息
                    return getPositionMap({"positionId":positionId}).then(obj =>{
                        this.presentPositionId = positionId;
                        this.presentNode =  positionNode;

                        let parentNode = this.positionMap.get(positionNode.parentId);

                        this.brotherPositionList = parentNode.children;
                        //页数计算
                        let index = parentNode.children.indexOf(this.presentNode) + 1;
                        //let sum = this.brotherPositionList.length;
                        //let pageSize = sum % 6 == 0 ? parseInt(sum/6):parseInt(sum/6)+1;
                        this.positionPage = index % 6 == 0 ? index / 6 : parseInt(index/6) + 1;
                        this.positionPage = this.positionPage == 0 ? 1 : this.positionPage;
                        //是否存在位置地图
                        if(obj != ""){
                            //存在地图
                            this.isHaveMap = true;
                            this.map.form = obj;
                            this.mapZoom = 100;
                            //查询在地图中的设备
                            this.getMapDevice(this.map.form.mapId)
                        }else{
                            //不存在地图
                            this.isHaveMap = false;
                            this.map.form = {
                                mapId:null,
                                mapImageId:null,
                                positionId:null
                            };
                            this.mapWidth=360;
                            this.mapHeight= 180;
                        }
                    }).finally(() => {
                        this.mapInitLoading = false;
                    })
                }else{
                    this.$message({
                        message: this.$t('iot.map.selectPosition'),
                        type: 'info'
                    });
                }
            },
            //递归获取位置坐标
            findParentName(node){
                let parentNode = this.positionMap.get(node.parentId);
                if(parentNode != null){
                    //positionLabel = parentNode.label+">"+positionLabel;
                    this.positionLabel.push(parentNode);
                    this.findParentName(parentNode);
                }
            },
            //查询在地图中的设备
            getMapDevice(mapId){
                getMapDeviceList({"mapId":mapId,"categoryId":0}).then(deviceList =>{
                    this.mapDeviceList = deviceList;
                    let monitorStr = "";
                    deviceList.map(device => {
                        if(device.monitorList) {
                            device.monitorList.map(v => {
                                monitorStr+=v.monitorCode+",";
                            })
                        }
                    })
                    if(monitorStr != ""){
                        store.dispatch('SetSubscribe',monitorStr.substr(0,monitorStr.length-1)).then(() => {
                            console.log('send websocket Subscribe:'+monitorStr)
                        }).catch((err) => {
                            console.log('send websocket Subscribe error: '+err)
                        })
                    }
                });
            },
            //地图放大缩小
            rollImg(event) {
                let zoom = parseInt(this.mapZoom) || 100;
                /* event.wheelDelta 获取滚轮滚动值并将滚动值叠加给缩放比zoom
                      wheelDelta统一为±120，其中正数表示为向上滚动，负数表示向下滚动
                  */
                zoom += event.wheelDelta / 12;
                /* 最小范围 和 最大范围 的图片缩放尺度 */
                if (zoom >= this.minZoom && zoom <this.maxZoom) {
                    this.mapZoom = zoom;
                }

                if(this.autoChange == 1){
                    if(zoom < (parseInt(this.minZoom) + 5)){
                        this.narrowPosition();
                    }
                    if(zoom > (parseInt(this.maxZoom) - 5)){
                        this.enlargePosition();
                    }
                }
            },
            //刷新地图
            refreshMap(){
                if(this.presentNode != null){
                    this.changeMap(this.presentNode.positionId);
                }else{
                    this.$message({
                        message: this.$t('iot.map.selectPosition'),
                        type: 'info'
                    });
                }
            },
            //减少位置索引
            reduceIndex(){
                if(this.positionPage>1){
                    this.positionPage = this.positionPage - 1;
                }
            },
            //增加位置索引
            addIndex(){
                let length  = this.brotherPositionList.length;
                let pageSize = parseInt(length/6);
                let maxPage = length % 6 == 0 ?  pageSize : pageSize + 1;
                if(this.positionPage < maxPage){
                    this.positionPage = this.positionPage + 1;
                }
            },
            //放大位置
            enlargePosition(){
                if(this.presentNode.children != undefined){
                    this.changeMap(this.presentNode.children[0].positionId);
                }
            },
            //缩小位置
            narrowPosition(){
                let parentNode = this.positionMap.get(this.presentNode.parentId);
                this.changeMap(parentNode.positionId);
            },
            //遥信消息
            telecommandMessageInfo(message){
                // console.log(message)
                this.mapDeviceList.map((device)=>{
                    if(device.monitorList) {
                        let output = device.monitorList.find((item)=>{
                            return item.monitorCode == message.monitorCode;
                        })
                        if(output != undefined){
                            if(output.monitorType == '001001'){
                                device.onOff = message.value;
                            }
                            output.value = message.value;
                        }
                    }

                });
            },
            //遥测消息
            telemeteringMessageInfo(message){
                // console.log(message)
                this.mapDeviceList.map((device)=>{
                    if(device.monitorList) {
                        let output = device.monitorList.find((item)=>{
                            return item.monitorCode == message.monitorCode;
                        })
                        if(output != undefined){
                            output.value = message.value;
                        }
                    }

                });
            },
            //告警消息
            alarmMessageInfo(message){
                // console.log(message[0])
                this.mapDeviceList.map((device)=>{
                    if(device.monitorList) {
                        let output = device.monitorList.find((item)=>{
                            return item.monitorCode == message.monitorCode;
                        })
                        if(output != undefined){
                            if(message.toState != 'Normal'){
                                device.ifAlarm = 1;
                            }else{
                                device.ifAlarm = 0;
                            }
                        }
                    }
                });
            },
            //开关控制
            setOnOffPoint(monitor,value){
                contorlPoint({"monitorCode":monitor.monitorCode,"value":value});
            },
            //设备详情
            deviceDetails(device){
                if(device.deviceType == "001007"){
                    console.log("高压进线柜")
                    // this.dialogVisible = true;
                    this.deviceInfo = device;
                    this.deviceType = device.deviceType;
                }else if(device.deviceType == "001008"){
                    console.log("抽屉柜")
                    // this.dialogVisible = true;
                    this.deviceInfo = device;
                    this.deviceType = device.deviceType;
                }else if(device.deviceType == "001009"){
                    console.log("双泵排水泵")
                    // this.dialogVisible = true;
                    this.deviceInfo = device;
                    this.deviceType = device.deviceType;
                }else if(device.deviceType == "001010"){
                    console.log("空调机组")
                    // this.dialogVisible = true;
                    this.deviceInfo = device;
                    this.deviceType = device.deviceType;

                }else if(device.deviceType == "001011"){
                    console.log("排风机")
                    // this.dialogVisible = true;
                    this.deviceInfo = device;
                    this.deviceType = device.deviceType;
                }else if(device.deviceType == "001012"){
                    findDeviceById({"deviceId":device.deviceId}).then(res=>{
                        console.log("摄像头")
                        console.log(res)
                        this.camera.playVisible = true
                        this.$nextTick(()=>{
                            media.play(this.$refs.video, res.deviceEui)
                        })
                    });
                }else{
                    if(device.monitorList != null){
                        let output = device.monitorList.find((item)=>{
                            return item.monitorType == '001001';
                        })
                        if(output != undefined){
                            let messageInfo = "";
                            let value = "";
                            if(device.onOff == "true"){
                                messageInfo = this.$t('iot.map.isCloseConfirm');
                                value = "false";
                            }else{
                                messageInfo = this.$t('iot.map.isOpenConfirm');
                                value = "true";
                            }
                            this.$confirm(messageInfo, this.$t('commons.warning'), {
                                confirmButtonText: this.$t('commons.confirm'),
                                cancelButtonText: this.$t('commons.cancel'),
                                type: 'warning'
                            }).then(() => {
                                contorlPoint({"monitorCode":output.monitorCode,"value":value});
                            }).catch(error => {
                                console.log(`控制失败，原因 => ${error}`)
                            });
                        }
                    }
                }
            },
            cancelPlay() {
                media.destroy(this.playCameraNo)
                this.camera.playVisible = false
            }
        }
    }
</script>

<style scoped>
    .positionSelect{
        width:280px;
        margin-top:8px;
        position: absolute;
        z-index:9999;
        max-height: 500px;
        overflow-x: scroll;
        box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04);
        border-radius: 4px;
    }
    .labelSpan{
        font-weight: 500;
        -webkit-font-smoothing: antialiased;
        text-rendering: optimizeLegibility;
        font-family: Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB, Microsoft YaHei, Arial, sans-serif;
    }
    .labelSpan >>> a:hover{
        color: #409EFF;
    }
    .toolUl {
        width: 100%;
        height: 306px;
        padding: 0px;
        margin-top: 8px;
        margin-bottom: 0px;
        transition:width 0.5s;
    }
    .liItem{
        display: block;
        position: absolute;
        right: 0px;
        width:40px;
        height: 40px;
        border-radius: 40px;
        overflow: hidden;
        box-shadow: inset 0px 1px 0px rgba(255, 255, 255, 0.3), 0 3px 2px rgba(0, 0, 0, 0.45);
        border-color: #0880d7;
        background: #2798eb;

        color: #fff;
        text-align: center;
        line-height: 40px;
        margin-bottom: 15px;
        transition:width 1s;
        -moz-transition:width 1s; /* Firefox 4 */
        -webkit-transition:width 1s; /* Safari and Chrome */
        -o-transition:width 1s; /* Opera */
        /*border-color: #0880d7;*/
        /*background: #2798eb;*/
    }

    .liItemActive{
        display: block;
        position: absolute;
        right: 0px;
        width:40px;
        height: 40px;
        border-radius: 40px;
        overflow: hidden;
        box-shadow: inset 0px 1px 0px rgba(255, 255, 255, 0.3), 0 3px 2px rgba(0, 0, 0, 0.45);
        background-color: #A5DE37;
        border-color: #A5DE37;

        color: #fff;
        text-align: center;
        line-height: 40px;
        margin-bottom: 15px;
        transition:width 1s;
        -moz-transition:width 1s; /* Firefox 4 */
        -webkit-transition:width 1s; /* Safari and Chrome */
        -o-transition:width 1s; /* Opera */
    }

    .liItem:hover,.liItemActive:hover {
        width:100%;
    }

    .liA{
        display: block;
        height: 40px;
        margin-bottom: 16px;
    }

    .toolUl:hover{
        width:100%;
    }

    .toolRight{
        float: right;
        cursor: pointer;
        margin-right: 12px;
    }

    @keyframes magentaPulse {
        from {
            background-color: rgba(255, 0, 0, 0.6);
            border-radius:10px;

            -webkit-box-shadow: 0 0 20px 3px red;
            box-shadow:0 0 20px 3px red;
        }
        25% {
            background-color: rgba(255, 255, 0, 0.6);
            border-radius:10px;
            -webkit-box-shadow: 0 0 20px 3px yellow;
            box-shadow:  0 0 20px 3px yellow;
        }
        50% {
            background-color: rgba(255, 0, 0, 0.6);
            border-radius:10px;

            -webkit-box-shadow: 0 0 20px 3px red;
            box-shadow: 0 0 20px 3px red;
        }
        80% {
            background-color: rgba(255, 255, 0, 0.6);
            border-radius:10px;
            -webkit-box-shadow: 0 0 20px 3px yellow;
            box-shadow: 0 0 20px 3px yellow;
        }
        to {
            background-color: rgba(255, 0, 0, 0.6);
            border-radius:10px;

            -webkit-box-shadow: 0 0 20px 3px red;
            box-shadow:0 0 20px 3px red;
        }
    }
    @-webkit-keyframes magentaPulse {
        from {
            background-color: rgba(255, 0, 0, 0.6);
            border-radius:10px;

            -webkit-box-shadow: 0 0 20px 3px red;
            box-shadow:0 0 20px 3px red;
        }
        25% {
            background-color: rgba(255, 255, 0, 0.6);
            border-radius:10px;
            -webkit-box-shadow: 0 0 20px 3px yellow;
            box-shadow:  0 0 20px 3px yellow;
        }
        50% {
            background-color: rgba(255, 0, 0, 0.6);
            border-radius:10px;

            -webkit-box-shadow: 0 0 20px 3px red;
            box-shadow: 0 0 20px 3px red;
        }
        80% {
            background-color: rgba(255, 255, 0, 0.6);
            border-radius:10px;
            -webkit-box-shadow: 0 0 20px 3px yellow;
            box-shadow: 0 0 20px 3px yellow;
        }
        to {
            background-color: rgba(255, 0, 0, 0.6);
            border-radius:10px;

            -webkit-box-shadow: 0 0 20px 3px red;
            box-shadow: 0 0 20px 3px red;
        }
    }
    /*  重复动画  */

    a.button {
        -webkit-animation-iteration-count: infinite;
        animation-iteration-count: infinite;
    }
    /*  动画名称、动画完成时间  */

    .magentaPulse.button {
        -webkit-animation-name: magentaPulse;
        animation-name: magentaPulse;
        -webkit-animation-duration: .8s;
        animation-duration: .8s;
        -webkit-animation-iteration-count: infinite;
        animation-iteration-count: infinite;
    }

    /*  动画闪烁颜色  */
    @keyframes magentaPulse {
        from {
            background-color: rgba(255, 0, 0, 0.6);
            border-radius:10px;

            -webkit-box-shadow: 0 0 20px 3px red;
            box-shadow:0 0 20px 3px red;
        }
        25% {
            background-color: rgba(255, 255, 0, 0.6);
            border-radius:10px;
            -webkit-box-shadow: 0 0 20px 3px yellow;
            box-shadow:  0 0 20px 3px yellow;
        }
        50% {
            background-color: rgba(255, 0, 0, 0.6);
            border-radius:10px;

            -webkit-box-shadow: 0 0 20px 3px red;
            box-shadow: 0 0 20px 3px red;
        }
        80% {
            background-color: rgba(255, 255, 0, 0.6);
            border-radius:10px;
            -webkit-box-shadow: 0 0 20px 3px yellow;
            box-shadow: 0 0 20px 3px yellow;
        }
        to {
            background-color: rgba(255, 0, 0, 0.6);
            border-radius:10px;

            -webkit-box-shadow: 0 0 20px 3px red;
            box-shadow:0 0 20px 3px red;
        }
    }
    @-webkit-keyframes magentaPulse {
        from {
            background-color: rgba(255, 0, 0, 0.6);
            border-radius:10px;

            -webkit-box-shadow: 0 0 20px 3px red;
            box-shadow:0 0 20px 3px red;
        }
        25% {
            background-color: rgba(255, 255, 0, 0.6);
            border-radius:10px;
            -webkit-box-shadow: 0 0 20px 3px yellow;
            box-shadow:  0 0 20px 3px yellow;
        }
        50% {
            background-color: rgba(255, 0, 0, 0.6);
            border-radius:10px;

            -webkit-box-shadow: 0 0 20px 3px red;
            box-shadow: 0 0 20px 3px red;
        }
        80% {
            background-color: rgba(255, 255, 0, 0.6);
            border-radius:10px;
            -webkit-box-shadow: 0 0 20px 3px yellow;
            box-shadow: 0 0 20px 3px yellow;
        }
        to {
            background-color: rgba(255, 0, 0, 0.6);
            border-radius:10px;

            -webkit-box-shadow: 0 0 20px 3px red;
            box-shadow:0 0 20px 3px red;
        }
    }
    /*  重复动画  */

    a.button {
        -webkit-animation-iteration-count: infinite;
        animation-iteration-count: infinite;
    }
    /*  动画名称、动画完成时间  */

    .magentaPulse.button {
        -webkit-animation-name: magentaPulse;
        animation-name: magentaPulse;
        -webkit-animation-duration: .8s;
        animation-duration: .8s;
        -webkit-animation-iteration-count: infinite;
        animation-iteration-count: infinite;
    }

    .bubble_bot_cor{
        display: block;
        width: 10px;
        height: 7px;
        background: url("../../../../../assets/images/cir.png");
        position: absolute;
        bottom: -6px;
        left: 12px;
    }
    .bubble_box{
        font-size: 12px;
        line-height: 12px;
        padding: 0px 5px;
        background: rgb(61, 75, 92);
        position: absolute;
        border-radius: 6px 6px;
        /*border: 1px solid rgba(230,237,244,.8);*/
        left: 4px;
        bottom: 55px;
        color: #e6edf4;
    }

    .bubble_box span{
        margin-left: 3px;
    }

    .downCard >>> .el-card__header {
        padding: 6px 10px;
    }

    .downCard >>> .el-card__body {
        padding: 0px;
    }

    .cardHeader {
        line-height: 34px;
        font-size: 14px;
    }

</style>

<style>
    .mapDeviceHidden{
        display: none;
    }
</style>
