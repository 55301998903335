<template>
    <el-dialog
            :visible.sync="dialogVisible"
            width="866px"
            top="6vh"
            :before-close="closeAirUnitDialog">
        <template slot="title">
            <title-icon/>空调机组
        </template>
        <div style="width: 100%;height: 560px;">
            <div class="modal-body fanAir">
                <div class="cg-newFanOne">
                    <img src="../../../../../../assets/images/newFanOne.png" style="vertical-align: middle;"/>
                </div>

                <div class="cg-air_temperature_humidity">
                    <span class="fanAir-tit">回风温湿度:</span>
                    <span class="fanAir-count">
                        <span>{{parseFloat(monitorType007001).toFixed(2)}}</span>
                        <span>°C</span>
                    </span>

                    <span class="fanAir-count">
                        <span>{{parseFloat(monitorType008001).toFixed(2)}}</span>
                        <span>%RH</span>
                    </span>
                </div>

                <div class="cg_air_temperature_co2">
                    <span class="fanAir-tit">回风CO2:</span>
                    <span class="fanAir-count">
                        <span>{{parseFloat(monitorType009001).toFixed(2)}}</span>
                        <span>ppm</span>
                    </span>
                </div>

                <div class="cg_push_fan_tem">
                    <span class="fanAir-tit">送风温度：</span>
                    <span class="fanAir-count">
                        <span>{{parseFloat(monitorType007002).toFixed(2)}}</span>
                        <span>°C</span>
                    </span>
                </div>

                <div class="cg_filter_alarm-t">
                    <span class="fanAir-tit">滤网报警</span>
                    <template v-if="monitorType010001 == 'true'">
                        <span class="fanAir-btn fault">报警</span>
                    </template>
                    <template v-else>
                        <span class="fanAir-btn">正常</span>
                    </template>
                </div>

                <div class="cg_antifreeze_alarm-t">
                    <span class="fanAir-tit">防冻报警</span>
                    <template v-if="monitorType010002 == 'true'">
                        <span class="fanAir-btn fault">报警</span>
                    </template>
                    <template v-else>
                        <span class="fanAir-btn">正常</span>
                    </template>
                </div>

                <div class="cg_filter_alarm-b">
                    <span class="fanAir-tit">静电滤网状态</span>
                    <template v-if="monitorType011001 == 'true'">
                        <span class="fanAir-btn fault">报警</span>
                    </template>
                    <template v-else>
                        <span class="fanAir-btn">正常</span>
                    </template>
                </div>

                <div class="cg-fanAir-hand-auto-1">
                    <span class="fanAir-tit">风机压差</span>
                    <template v-if="monitorType012001 == 'true'">
                        <span class="fanAir-btn fault">报警</span>
                    </template>
                    <template v-else>
                        <span class="fanAir-btn">正常</span>
                    </template>
                </div>

                <div class="cg-fanAir-hand-auto-2">
                    <span class="fanAir-tit">手自动状态</span>
                    <template v-if="monitorType013001 == 'true'">
                        <span class="fanAir-btn autoHand">自动</span>
                    </template>
                    <template v-else>
                        <span class="fanAir-btn autoHand">手动</span>
                    </template>
                </div>

                <div class="cg-fanAir-hand-auto-3">
                    <span class="fanAir-tit">风机故障状态</span>
                    <template v-if="monitorType010004 == 'true'">
                        <span class="fanAir-btn fault">故障</span>
                    </template>
                    <template v-else>
                        <span class="fanAir-btn">正常</span>
                    </template>
                </div>

                <div class="cg-fanAir-hand-auto-4">
                    <span class="fanAir-tit">风机运行状态</span>
                    <template v-if="monitorType001001 == 'true'">
                        <span class="fanAir-btn">运行</span>
                    </template>
                    <template v-else>
                        <span class="fanAir-btn stop">停止</span>
                    </template>
                </div>
            </div>
        </div>
        <div slot="footer">
            <el-button type="primary" @click="closeAirUnitDialog">{{$t('commons.close')}}</el-button>
        </div>
    </el-dialog>
</template>

<script>
    export default {
        name: 'AirUnit',
        props: {
            // //是否显示
            // dialogVisible: {
            //     type: Boolean
            // },
            // 报警信息
            deviceInfo: {
                type: Object,
            },
            // 设备类型编码
            deviceType: {
                type: String,
            },
        },
        computed: {
            telecommandMessage() {
                return this.$store.getters.telecommandMessage;
            },
            telemeteringMessage(){
                return this.$store.getters.telemeteringMessage;
            },
            getDeviceType() {
                return this.deviceType;
            }
        },
        watch: {
            //侦听遥信数据
            telecommandMessage(message) {
                this.telecommandMessageInfo(message);
            },
            //侦听遥测数据
            telemeteringMessage(message){
                this.telemeteringMessageInfo(message);
            },
            getDeviceType: function () {
                this.deviceTypeChange();
            }
        },
        data() {
            return {
                dialogVisible:false,
                currentDeviceType: '001010',
                monitorType001001: null,
                monitorType007001: null,
                monitorType008001: null,
                monitorType009001: null,
                monitorType007002: null,
                monitorType010001: null,
                monitorType010002: null,
                monitorType011001: null,
                monitorType012001: null,
                monitorType013001: null,
                monitorType010004: null,
            }
        },
        methods: {
            deviceTypeChange(){
                if (this.currentDeviceType == this.deviceType) {
                    this.dialogVisible = true;
                    this.initMonitorRedisData();
                }
            },
            initMonitorRedisData(){
                this.deviceInfo.monitorList.map(v =>{
                    this.findMonitorTypeValue(v.monitorType);
                })
            },
            findMonitorTypeValue(monitorType){
                let monitorObj = this.deviceInfo.monitorList.find((item)=>{
                    return item.monitorType == monitorType;
                })
                this["monitorType"+monitorType] = monitorObj.value;
            },
            finMonitorCodeValue(message){
                if(this.deviceInfo != null) {
                    let monitorObj = this.deviceInfo.monitorList.find((item) => {
                        return item.monitorCode == message.monitorCode;
                    })
                    if (monitorObj != undefined) {
                        this["monitorType" + monitorObj.monitorType] = message.value;
                    }
                }
            },
            closeAirUnitDialog(){
                this.$emit('update:deviceType', null)
                this.dialogVisible = false;
            },
            telecommandMessageInfo(message){
                this.findMonitorCodeValue(message);
            },
            telemeteringMessageInfo(message){
                this.findMonitorCodeValue(message);
            }
        }
    }
</script>

<style scoped>
.cg-air_temperature_humidity {
    position: absolute;
    top: 30px;
    left: 240px;
}
.fanAir-tit {
    display:block;
    color: #666;
}
.fanAir-count {
    display: block;
    font-size: 16px;
    font-weight: 600;
}
.cg_air_temperature_co2 {
    position: absolute;
    top: 30px;
    left: 450px;
}
.cg_push_fan_tem {
    position: absolute;
    top: 229px;
    right: 31px;
}
.fanAir-btn {
    display: inline-block;
    width: 70px;
    height: 28px;
    line-height: 28px;
    text-align: center;
    color: #fff;
    background-color: #3ba537;
    margin-top: 6px;
}
.cg_filter_alarm-t {
    text-align: center;
    position: absolute;
    top: 410px;
    left: 250px;
}
.cg_filter_alarm-b {
    text-align: center;
    position: absolute;
    top: 470px;
    left: 243px;
}
.cg_antifreeze_alarm-t {
    text-align: center;
    position: absolute;
    top: 410px;
    left: 375px;
}
.cg-fanAir-hand-auto-1 {
    text-align: center;
    position: absolute;
    top: 410px;
    right: 200px;
}
.cg-fanAir-hand-auto-2 {
    text-align: center;
    position: absolute;
    top: 470px;
    right: 200px;
}
.cg-fanAir-hand-auto-3 {
    text-align: center;
    position: absolute;
    top: 530px;
    right: 193px;
}
.cg-fanAir-hand-auto-4 {
    text-align: center;
    position: absolute;
    top: 590px;
    right: 193px;
}
.autoHand{
    background-color: #3190c6;
}
.stop{
    background-color: #afb9bb;
}
.fault{
    background-color: #f43d3d;
}
</style>
