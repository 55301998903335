import request from '@/utils/request';
import { envInfo } from '@/constants/envInfo';
import { downTemplate } from '@/utils/download';

/**
 * 设备分页查询
 * @param params
 */
export function findDevicePage(params) {
  return request({
    url: envInfo.bgApp.consolePath + '/device/page',
    method: 'get',
    params,
  });
}

/**
 * 创建设备
 * @param params
 */
export function createDevice(params) {
  return request({
    url: envInfo.bgApp.consolePath + '/device/create',
    method: 'post',
    data: params,
  });
}

/**
 * 更新设备
 * @param params
 */
export function updateDevice(params) {
  return request({
    url: envInfo.bgApp.consolePath + '/device/update',
    method: 'post',
    data: params,
  });
}

/**
 * 删除设备
 * @param params
 */
export function deleteDeviceById(params) {
  return request({
    url: envInfo.bgApp.consolePath + '/device/delete/' + params.deviceId,
    method: 'delete',
  });
}

/**
 * 查询设备信息
 * @param params
 */
export function findDeviceById(params) {
  return request({
    url: envInfo.bgApp.consolePath + '/device/find/deviceById',
    method: 'get',
    params,
  });
}

/**
 * 批量删除设备
 * @param params
 */
export function batchDeleteDevice(params) {
  return request({
    url: envInfo.bgApp.consolePath + '/device/batchDelete',
    method: 'delete',
    params,
  });
}

/**
 * 导出设备
 * @param params
 */
export function exportDevice(params) {
  return request({
    url: envInfo.bgApp.consolePath + '/device/export',
    method: 'post',
    data: params,
  });
}

/**
 * 下载设备模版
 */
export function downDeviceTemplate() {
  downTemplate('ruge.device', envInfo.bgApp.consolePath);
}

/**
 * 根据位置查询设备列表
 */
export function getDeviceByPosition(params) {
  params.current = 1;
  params.rowCount = 200;
  params.resultMode = 2;
  return request({
    url: envInfo.bgApp.consolePath + '/device/page',
    method: 'get',
    params,
  });
}

/**
 * 汇总项目下相关维度的设备数量
 * @param params
 */
export function summaryDevice(type, params) {
  let url = envInfo.bgApp.apiPath + '/deviceData/summary/' + type;
  return request({
    url: url,
    method: 'get',
    params,
  });
}
